<template>
  <div>
    <Breadcrumbs
      page_title="quiz"
      :item_no="item_no ? item_no : 0"
      :items="breadcrumbs"
      :item_btn_add="true"
      :btn_text="'addquiz'"
      @ShowAction="ShowAction"
    />

    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <v-container>
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7" style="text-align: right !important">
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #a6cc39;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>
            <v-btn
              outlined
              @click="Quizreport"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #a6cc39;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("quizreport") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="showFilterTools" class="ma-1">
          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <div class="pre-fix">
              <label
                for="quizname"
                style="
                  font-size: 16px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("quizname") }}</label
              >
              <v-select
                v-model="QuizNameIdSelect"
                :items="QuizNameItems"
                item-text="quizName"
                item-value="quizId"
                hide-no-data
                placeholder="-- Please select --"
                class="quiz-name"
                hide-details
                solo
                dense
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="getSelectAll">
                    <v-list-item-action>
                      <v-icon
                        :color="QuizNameIdSelect.length > 0 ? '#a6cc39' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.quizName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ QuizNameIdSelect.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2" xl="2">
            <label
              for="createdateM"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("createdateM") }}</label
            >
            <v-menu
              v-model="frommenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromdate"
                  class="ml-3"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  placeholder="-- Please select --"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromdate"
                @input="startDateDis()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-divider inset style="border-color: #424242"></v-divider>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
            xl="2"
            style="padding-top: 35px"
          >
            <v-menu
              v-model="tomenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ml-3"
                  v-model="todate"
                  prepend-inner-icon="mdi-calendar-blank-outline"
                  outlined
                  dense
                  readonly
                  placeholder="-- Please select --"
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="todate"
                @input="endDateDis(), (tomenu = false)"
                :allowed-dates="allowedDatesEnd"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
            style="padding-top: 2.2rem"
          >
            <v-btn
              text
              class="text-capitalize"
              height="40"
              style="
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                color: #424242;
                font-size: 16px;
              "
              @click="clearAll()"
              >{{ $t("clearall") }}</v-btn
            >
            <v-btn
              width="120"
              height="40"
              class="text-capitalize btn_hover_effect ml-5"
              style="
                color: #ffff;
                border: 1px solid #a6cc39;
                border-radius: 8px;
                font-size: 16px;
              "
              color="#A6CC39"
              @click="GetQuizMasterList(0)"
              >{{ $t("search") }}</v-btn
            >
          </v-col>
        </v-row>

        <v-row class="ma-1">
          <v-col>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="quiz_headers"
              :items="quizItems"
              hide-default-footer
              class="quiz-table"
              :search="search"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-xs-start">{{ item.no }}</td>
                  <td class="text-xs-center">
                    <p>{{ item.quizName }}</p>
                  </td>
                  <td class="text-xs-start">
                    <p>{{ item.question }}</p>
                  </td>

                  <td class="text-xs-start">
                    <p style="width: 80px">{{ item.respondedUser }}</p>
                  </td>
                  <td class="text-xs-center">
                    <p style="width: 90px">{{ item.courseName }}</p>
                  </td>
                  <td class="text-xs-start">
                    <p style="width: 80px">{{ item.quizTypeName }}</p>
                  </td>
                  <td class="text-xs-start">
                    <p>{{ item.createQuizDate }}</p>
                  </td>
                  <td class="text-xs-start">
                    <p>{{ item.createBy }}</p>
                  </td>
                  <td>
                    <div class="d-flex" style="margin-top: -5px">
                      <v-btn
                        color="#A6CC39"
                        text
                        style="font-size: 16px"
                        class="text-capitalize"
                        @click="CopyQuizMaster(item)"
                      >
                        <v-icon left>mdi-content-copy</v-icon>
                        {{ $t("copy") }}
                      </v-btn>
                      <v-btn
                        color="#4FB14E"
                        text
                        style="font-size: 14px"
                        class="text-capitalize"
                        @click="editassignmentcourse(item)"
                      >
                        <v-icon left>mdi-square-edit-outline</v-icon>
                        {{ $t("edit") }}
                      </v-btn>
                      <v-btn
                        color="#FF6060"
                        text
                        style="font-size: 14px"
                        class="text-capitalize"
                        @click="checkAssignmentDelete(item)"
                      >
                        <v-icon left>mdi-delete-outline</v-icon>
                        {{ $t("delete") }}
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row justify="space-between" class="mx-3 my-4">
          <v-row>
            <v-col cols="auto" class="mr-auto">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </v-col>

            <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show") }}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #c0c0c0; opacity: 1"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="itemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-card>

    <!-- Delete -->
    <v-dialog v-model="delete_quiz" width="587">
      <v-card>
        <v-card-title class="justify-start">
          <p style="font-size: 30px; color: #363636">
            {{ $t("deleteconfirm") }}
          </p>
        </v-card-title>

        <v-card-text>
          <p class="pt-4 text-left" style="font-size: 16px; color: #363636">
            {{ $t("suredeletenew") + " ' " + this.delete_quizname + " ' " }} ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            width="100"
            text
            @click="delete_quiz = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            color="#FF6060"
            style="font-size: 20px; border-radius: 8px; box-shadow: unset"
            class="text-capitalize white--text mb-3"
            @click="DeleteQuizMaster()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Quiz Rerport Dialog -->
    <v-dialog
      persistent
      v-model="QuizReport"
      width="700"
      max-height="800"
      style="overflow: hidden"
    >
      <v-card>
        <v-card-title
          style="
            font-size: 20px;
            height: 70px;
            border-bottom: 1px solid #c0c0c0;
          "
        >
          <p>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("quizreport") }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="close()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="d-flex mt-3 mx-4">
          <v-col cols="6" md="6" lg="5">
            <v-text-field
              outlined
              dense
              clearable
              style="border-radius: 8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="searchquizreport"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-end">
          <v-checkbox
            v-model="ex4"
            label="Select All"
            color="#A6CC39"
            value=""
            hide-details
            @click="getAllQuiz"
          ></v-checkbox>
        </v-card-actions>
        <v-card-text>
          <div class="mt-1">
            <v-progress-circular
              v-if="actionloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="addQuizheaders"
              :items="quizreport"
              hide-default-footer
              class="quiz-table"
              :search="searchquizreport"
              @page-count="pageCount = $event"
              :page.sync="Quizreportpage"
              :items-per-page="itemsPerPage"
              @toggle-select-all="getSelectAll"
              item-key="quizId"
              v-model="selected"
              show-select
            >
            </v-data-table>
          </div>
          <v-row justify="end" class="mx-3 my-4">
            <v-col cols="auto">
              <v-pagination
                circle
                v-model="Quizreportpage"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="close()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            depressed
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="exportExcel"
            >{{ $t("exportexcel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
import * as moment from "moment/moment";

export default {
  components: {},

  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    showFilterTools: false,
    item_no: 0,
    delete_quizname: "",
    delete_quiz: false,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    itemsPerPage: 10,
    pageCount: 0,
    page: 1,
    Quizreportpage: 1,
    search: null,
    searchquizreport: null,
    loading: false,
    itemDelete: null,
    QuizReport: false,
    actionloading: false,
    quizItems: [
      // {
      //   no: "1",
      //   quizName: "Marketing",
      //   question: "10",
      //   createBy: "Junne",
      //   createDate: "5/08/2022",
      //   courseName: "Marketing",
      //   totalUser: "15",
      //   respondedUser: "3",
      // },
      // {
      //   no: "2",
      //   quizName: "SEO",
      //   question: "15",
      //   createBy: "Junne",
      //   createDate: "3/08/2022",
      //   courseName: "-",
      //   totalUser: "-",
      //   respondedUser: "-",
      // },
    ],
    frommenu: false,
    tomenu: false,
    todate: "",
    fromdate: "",
    QuizNameItems: [], //"Marketing", "SEO", "Brand"
    tempTitle: [],
    QuizNameIdSelect: [],
    ex4: false,
    quizreport: [],
    selected: [],
    UserItems1: [],
    breadcrumbs: [
      {
        //image: "house.png",
        sidebar_tilte: "home",
      },
      {
        text: "quiz",
      },
    ],
    dateoffset: new Date().getTimezoneOffset(),
    colorCodes: ["FFEB9C", "FFC7CE", "C6EFCE", "DAEEF3", "FCD5B4"],
    rawColor: null,
  }),

  mounted() {
    this.GetQuizDropDownList();
    this.GetQuizMasterList(1);
  },

  computed: {
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    quiz_headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("quizname"),
          align: "left",
          value: "quizName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("questions"),
          align: "left",
          value: "question",
          class: "tableColor",
          sortable: false,
        },

        {
          text: this.$t("respondeduser"),
          align: "left",
          value: "respondedUser",
          sortable: true,
          class: "tableColor",
        },
        {
          text: this.$t("assignmentcourse"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("quiztype"),
          sortable: true,
          align: "left",
          value: "quizTypeName",
          class: "tableColor",
        },
        {
          text: this.$t("createdateM"),
          align: "left",
          value: "createDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("createby"),
          align: "left",
          value: "createBy",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("action"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    addQuizheaders() {
      return [
        {
          text: this.$t("quizname"),
          align: "left",
          value: "quizName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("totaluser"),
          align: "left",
          value: "totalUser",
          sortable: true,
          class: "tableColor",
        },
        {
          text: this.$t("respondeduser"),
          align: "left",
          value: "respondedUser",
          sortable: true,
          class: "tableColor",
        },
        {
          text: "Quiz Type",
          align: "left",
          value: "respondedUser",
          sortable: true,
          class: "tableColor",
        },
      ];
    },
  },

  methods: {
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colorCodes.length);
      const rawColor = this.colorCodes[randomIndex];
      return rawColor;
    },
    async exportExcel() {
      let that = this;
      that.actionloading = true;

      const selectedQuizIds = that.selected.map((item) => item.quizId);
      const selectedQuizName = that.selected.map((item) => item.quizName);
      let request = {
        companyId: localStorage.getItem("companyID"),
        quizId: selectedQuizIds,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetUserQuizExportListV2`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.UserItems1 = response.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              finished: moment(v.finished).local().format("DD/MM/YYYY  LT"),
            }));
            that.actionloading = false;
            that.QuizReport = false;
            that.selected = [];
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });

      that.exportDialog = false;
      const headers = [
        "NO",
        "FULL NAME",
        "GROUP",
        "QUIZ NAME",
        "ASSIGNMENT COURSE",
        "CORRECTED",
        "WRONG",
        "SCORE",
        "FINISHED",
      ];
      const keys = [
        "no",
        "fullName",
        "group",
        "quizName",
        "courseName",
        "corrected",
        "wrong",
        "totalScore",
        "finished",
      ];
      const fileName = `${"QuizReport"}.xlsx`;
      const filterItems = that.UserItems1.filter((item) =>
        selectedQuizName.includes(item.quizName)
      );
      const data = [];
      data.push(headers);

      for (const item of filterItems) {
        let rowOne = [];

        for (const key of keys) {
          rowOne.push(item[key] || " ");
        }
        data.push(rowOne);
        rowOne[headers.indexOf("NO")] = item.no;
        rowOne[headers.indexOf("FULL NAME")] = item.fullName;
        rowOne[headers.indexOf("GROUP")] = item.group;
        rowOne[headers.indexOf("QUIZ NAME")] = item.quizName;
        rowOne[headers.indexOf("ASSIGNMENT COURSE")] = item.courseName;
        rowOne[headers.indexOf("CORRECTED")] = item.corrected;
        rowOne[headers.indexOf("WRONG")] = item.wrong;
        rowOne[headers.indexOf("SCORE")] = item.totalScore;
        rowOne[headers.indexOf("FINISHED")] = item.finished;
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Quiz Report", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);
      const quizIdToColor = new Map();
      let assignedColors = new Set();

      data.forEach((element, index) => {
        worksheet2.addRow(element);
        const quizId = filterItems[index - 1]?.quizName;

        let randomColor;

        if (quizIdToColor.has(quizId)) {
          randomColor = quizIdToColor.get(quizId);
        } else {
          const availableColors = that.colorCodes.filter(
            (color) => !assignedColors.has(color)
          );

          if (availableColors.length > 0) {
            randomColor =
              availableColors[
                Math.floor(Math.random() * availableColors.length)
              ];
            assignedColors.add(randomColor);
            quizIdToColor.set(quizId, randomColor);
            if (assignedColors.size == 5) {
              assignedColors = new Set();
            }
          } else {
            // Fallback to random color if no available colors
            randomColor =
              that.colorCodes[
                Math.floor(Math.random() * that.colorCodes.length)
              ];
          }
        }
        worksheet2.getRow(index + 1).eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: randomColor },
            bgColor: { argb: randomColor },
          };
          cell.font = {
            name: "Calibri",
            size: 11,
            color: { argb: "424242" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell(function (cell) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell(function (cell) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach(function (column, i) {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then(function (val) {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async close() {
      this.QuizReport = false;
      this.selected = [];
    },
    async Quizreport() {
      this.QuizReport = true;
    },
    clearAll() {
      this.search = null;
      this.todate = "";
      this.fromdate = "";
      this.GetQuizDropDownList();
      this.GetQuizMasterList(1);
    },
    ShowAction(v) {
      let checkPermission = this.check_Permissions("quiz");
      if (checkPermission) {
        if (v == "Add") {
          this.$router.push("/home/addquiz/add-quiz");
        }
      } else {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to add quiz.";
      }
    },
    async checkAssignmentDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("quiz");
      if (checkPermission == false) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Quiz.";
        return false;
      }

      self.itemDelete = item;
      self.delete_quiz = true;
      self.delete_quizname = item.quizName;
    },
    startDateDis() {
      this.frommenu = false;
      if (this.todate == "") {
        this.todate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.fromdate > this.todate) {
        this.todate = this.fromdate;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.fromdate;
    },

    endDateDis() {
      if (this.fromdate == "") {
        this.fromdate = this.todate;
      }
    },
    editassignmentcourse(itemp) {
      let that = this;
      let checkPermission = that.check_Permissions("quiz");
      if (checkPermission === false) {
        that.permissionDialog = true;
        that.permissionMessage = "You don't have permission to edit Quiz.";
        return false;
      }
      localStorage.setItem("QuizId", itemp.quizId);
      that.$router.push("/home/editquiz/edit-quiz");
    },

    getSelectAll() {
      if (this.QuizNameIdSelect.length == 0) {
        this.QuizNameIdSelect = this.QuizNameItems.map(({ quizId }) => quizId);
      } else {
        this.QuizNameIdSelect = [];
      }
    },
    getAllQuiz() {
      if (this.ex4) {
        this.selected = this.quizreport.slice();
      } else {
        this.selected = [];
      }
    },

    async GetQuizDropDownList() {
      let that = this;
      that.tempTitle = [];
      axios
        .get(
          `${that.web_urlV4}Quiz/GetQuizDropDownList?CompanyId=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.QuizNameItems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.QuizNameItems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetQuizMasterList(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let startDate;
      if (page == 1) {
        startDate = "";
      } else if (that.fromdate + " 00:00" == " 00:00") {
        startDate = "";
      } else {
        startDate = that.fromdate + " 00:00";
      }
      let enddate;
      if (page == 1) {
        enddate = "";
      } else if (that.todate + " 23:59" == " 23:59") {
        enddate = "";
      } else {
        enddate = that.todate + " 23:59";
      }

      let request = {
        companyID: localStorage.getItem("companyID"),
        startCreateDate: startDate,
        endCreateDate: enddate,
        quizId: that.QuizNameIdSelect,
        offset: that.dateoffset,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.quizItems = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
            that.quizreport = that.quizItems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      that.item_no = that.quizItems ? that.quizItems.length : 0;
    },
    async CopyQuizMaster(item) {
      let that = this;
      let checkPermission = that.check_Permissions("quiz");
      if (checkPermission === false) {
        that.permissionDialog = true;
        that.permissionMessage = "You don't have permission to copy Quiz.";
        return false;
      }
      that.itemsList = [];
      that.loading = true;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;
      let request = {
        companyID: localStorage.getItem("companyID"),
        mobile: false,
        createOrUpdateBy: memberid,
        quizId: item.quizId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/CopyQuizMaster`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            alert("CopyQuizMaster");
            that.GetQuizMasterList(0);
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },

    async DeleteQuizMaster() {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;
      let request = {
        companyID: localStorage.getItem("companyID"),
        mobile: false,
        createOrUpdateBy: memberid,
        quizId: that.itemDelete.quizId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/DeleteQuizMaster`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            alert("Delete");
            that.GetQuizMasterList(0);
            that.GetQuizDropDownList();
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
      that.delete_quiz = false;
    },
  },
};
</script>

<style scoped>
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .pre-fix .v-input__slot {
  border: 0.5px solid #c0c0c0;
  font-size: 16px !important;
  height: 40px;
  border-radius: 8px !important;
  box-shadow: unset !important;
}
.v-text-field fieldset,
.v-text-field .v-input__control {
  border: 0.5px solid #c0c0c0;
  border-radius: 8px;
}
::v-deep .from-date .v-input__slot {
  width: 200px !important;
}
::v-deep .to-date .v-input__slot {
  width: 200px !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
/* table related.... */
::v-deep .quiz-table tr:nth-child(even) {
  background: unset;
  margin-top: 0.5rem;
}
::v-deep .theme--light.v-label {
  color: #47484b !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
</style>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
.v-divider--inset:not(.v-divider--vertical) {
  max-width: 10px;
  margin-top: 3.5rem;
  margin-left: 0px;
}
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
</style>
